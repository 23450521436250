import React from "react"
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagramSquare, faFacebookSquare, faYoutubeSquare, faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import {LINKS} from '../components/constants';

const Footer = ({data}) => {

    return (
        <footer className="bg-ref-light">
            <div className="wrapper section">
                <div className="text-center">
                    <h2>Verger Joannette</h2>
                    <div className="footer-subtitle">Biologique</div>
                    <div className="footer-address">600, Chemin Principal, St-Joseph-du-Lac, QC, Canada</div>
                    <br></br><br></br>
                    <div className="footer-hours-title">Heures d'ouverture</div>
                    <div className="footer-address">Lundi — <b>Fermé</b></div>
                    <div className="footer-address">Mardi — <b>Fermé</b></div>
                    <div className="footer-address">Mercredi — <b>Fermé</b></div>
                    <div className="footer-address">Jeudi — <b>Fermé</b></div>
                    <div className="footer-address">Vendredi — <b>Fermé</b></div>
                    <div className="footer-address">Samedi — <b>Fermé</b></div>
                    <div className="footer-address">Dimanche — <b>Fermé</b></div>
                    <div className="footer-address">Ouverture de la saison 2024 le 31 août!</div>
                </div>

                <div className='footer-columns'>
                    <div className='footer-row'>
                        <div className='footer-column'>
                            {LINKS[process.env.GATSBY_LANG].map((link, i) => (
                                <Link to={link.url} className={"home-link text-bold"} key={"footer-link-" + i}>{link.name} &gt;</Link>
                            ))}
                        </div>
                        <div className='footer-column flex-center'>
                            <div className="footer-info">
                                <b>Questions?</b>
                                <br></br>
                                <a href="https://www.messenger.com/t/vergerjoannette" 
                                    className="fb-message-blue" 
                                    target="_blank">
                                    <FontAwesomeIcon icon={faFacebookMessenger} /> Envoyez-nous un message</a>
                                <br></br>
                                info@vergerjoannette.ca
                                <p>
                                    <a className="social-link" target="_blank" href="https://instagram.com/vergerbio">
                                        <FontAwesomeIcon icon={faInstagramSquare} />
                                    </a>
                                    <a className="social-link" target="_blank" href="https://facebook.com/vergerjoannette">
                                        <FontAwesomeIcon icon={faFacebookSquare} />
                                    </a>
                                    <a className="social-link" target="_blank" href="https://www.youtube.com/channel/UCEJoNecDAAeiy1I-TPfKWHg/featured">
                                        <FontAwesomeIcon icon={faYoutubeSquare} />
                                    </a>
                                </p>
                                <p>
                                    Développement par <a href="https://alexisgj.com" target="_blank">Alexis GJ</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-credits">
                    Verger Joannette Bio &copy; 2023. <a href="#">Conditions d'utilisation</a>
                </div>

            </div>
        </footer>
    )
}

export default Footer;